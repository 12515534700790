<template>
	<div>
		<div class="b-w pr">
			<input type="text" v-model="name"/>
			<i @click="clear">ㄨ</i>
		</div>
		<s-button btnText="保存" @s-click="modifyName"></s-button>
	</div>
</template>
<script>
import sButton from '@/views/common/button.vue';
import { updateNickName } from '@/request/api/user';
export default {
	data () {
		return {
			name: ''
		};
	},
	components: { sButton },
	created () {
		this.name = this.$route.query.name;
	},
	methods: {
		modifyName () {
			if (!this.name) {
				this.$root.elMsg('请输入姓名', 'fail');
			} else {
				updateNickName({ name: this.name }).then(data => {
					if (data && data === 'retry') {
						this.modifyName();
					} else if (data && data.ok === 1) {
						this.$root.elMsg('昵称设置成功');
						this.$root.go(-1);
					}
				});
			}
		},
		clear () {
			this.name = '';
		}
	}
};
</script>
<style lang="less" scoped>
	.b-w {
		height: 55px;
		input {
			outline: none;
			border: none;
			height: 100%;
			width: 100%;
			padding-left: 15px;
			font-size: 15px;
		}
		i {
			width: 16px;
			height: 16px;
			color: white;
			background: rgba(0, 0, 0, 0.13);
			text-align: center;
			line-height: 16px;
			font-size: 12px;
			position: absolute;
			border-radius: 50%;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	/deep/ .btn-container {
		background: none;
		margin-top: 25px;
	}
</style>